<template>
  <section v-bind="heroObj" class="relative flex flex-col py-16 lg:pt-0 lg:flex-col lg:pb-0">
    <div class="flex flex-col items-start w-full max-w-xl px-4 mx-auto lg:px-8 lg:max-w-screen-xl">
      <div class="mb-16 lg:my-40 lg:max-w-lg lg:pr-5">
        <div class="max-w-xl mb-6">
          <h1 class="max-w-lg mb-6 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:leading-none">
            {{heroObj.tagline}}
          </h1>
          <p class="text-base text-gray-700 md:text-lg">
            {{heroObj.description}}
          </p>
        </div>
        <div class="flex flex-col items-center md:flex-row">
            <GlowBtn :hero-obj="heroObj"/>
            
            <!-- <a href="#"
                class="inline-flex items-center justify-center w-full h-12 px-6 mb-3 font-medium tracking-wide text-white transition duration-200 rounded shadow-md md:w-auto md:mr-4 md:mb-0 bg-green-400 hover:bg-green-700 focus:shadow-outline focus:outline-none">
                {{heroObj.cta}}
            </a> 
            -------------------------->
            <!-- <div class="relative">
                <div class="absolute inset-0 bg-green-600 rounded-lg"></div>
                <button href="#"
                    class="px-7 py-4 bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600">
                    <span class="flex items-center space-x-5">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4" />
                        </svg>
                    <span class="text-gray-100 pr-6">Labs Release 2021.09</span>
                    </span>
                    <span class="text-green-400 pl-6">See what's new &rarr;</span>
                     {{heroObj.cta}}
                </button>
            </div> -------------------------->
            <!-- <a href="https://www.linkedin.com/in/scottlind/" target="_blank" rel="noreferrer" class="mx-6 text-gray-500 transition-colors duration-300 hover:text-blue-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
            </svg>
            </a>
            <a href="https://github.com/scottlinddk" target="_blank" rel="noreferrer" class="text-gray-500 transition-colors duration-300 hover:text-purple-600">
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
                <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
            </svg>
            </a> -->
        </div>
      </div>
    </div>
    <div class="inset-y-0 right-0 w-full max-w-xl px-4 mx-auto lg:pl-8 lg:pr-0 lg:mb-0 lg:mx-0 lg:w-1/2 lg:max-w-full lg:absolute xl:px-0">
      <img
        class="object-cover w-full h-56 rounded shadow-lg lg:rounded-none lg:shadow-none sm:h-96 lg:h-full"
        src="https://images.pexels.com/photos/3184287/pexels-photo-3184287.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=2&amp;h=750&amp;w=1260"
        alt=""
      />
    </div> 
</section>
<!-- <section class="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
<div class="max-w-xl sm:mx-auto lg:max-w-2xl">
    <div class="flex flex-col mb-16 sm:text-center sm:mb-0">
    <a href="/" class="mb-6 sm:mx-auto">
        <div class="flex items-center justify-center w-12 h-12 rounded-full bg-indigo-50">
        <svg class="w-10 h-10 text-deep-purple-accent-400" stroke="currentColor" viewBox="0 0 52 52">
            <polygon stroke-width="3" stroke-linecap="round" stroke-linejoin="round" fill="none" points="29 13 14 29 25 29 23 39 38 23 27 23"></polygon>
        </svg>
        </div>
    </a>
    <div class="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 class="max-w-lg mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto">
        <span class="relative inline-block">
            <svg viewBox="0 0 52 24" fill="currentColor" class="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block">
            <defs>
                <pattern id="e77df901-b9d7-4b9b-822e-16b2d410795b" x="0" y="0" width=".135" height=".30">
                <circle cx="1" cy="1" r=".7"></circle>
                </pattern>
            </defs>
            <rect fill="url(#e77df901-b9d7-4b9b-822e-16b2d410795b)" width="52" height="24"></rect>
            </svg>
            <span class="relative">The</span>
        </span>
        quick, brown fox jumps over a lazy dog
        </h2>
        <p class="text-base text-gray-700 md:text-lg">
        Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque rem aperiam, eaque ipsa quae.
        </p>
    </div>
    <div>
        <a
        href="/"
        class="inline-flex items-center justify-center h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
        >
        Get started
        </a>
    </div>
    </div>
</div>
</section> -->


</template>

<script>
import axios from 'axios';

import GlowBtn from './GlowBtn.vue'

export default {
  name: 'Hero',
  components: {
      GlowBtn
  },
  props: {
      
  },
  data() {
    return {
      api: process.env.VUE_APP_API_HOME,
      heroObj: {},
      errors: []
    }
  },
  async mounted () {
    try {
      const response = await axios.get(this.api)
      this.heroObj = response.data[0].acf
      console.log(this.heroObj)
      // console.log(this.$route.params.slug)
    } catch (e) {
      this.errors.push(e)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
