<template>
<footer class="dark:bg-gray-600 bg-gradient-to-r from-green-50 to-blue-50 px-2 md:px-8 lg:px-16 pt-12 mx-auto">
  
  <div class="grid gap-10 row-gap-6 mb-8 sm:grid-cols-2 lg:grid-cols-4">
    <div class="sm:col-span-2">
      <router-link to="/" aria-label="Gå til hjem" title="Scott Lind" class="inline-flex items-center">
        <span class="text-xl dark:text-white font-sans font-bold tracking-wide text-gray-800 uppercase">
          Scott Lind
        </span>
      </router-link>
      <div class="mt-2 lg:max-w-sm">
        <p class="text-sm dark:text-white text-gray-800">
          Multimediedesignstuderende der elsker frontend-udvikling og som har en baggrund i interaktionsdesign.
        </p>
      </div>
    </div>
    <div class="space-y-2 text-sm">
      <p class="text-base dark:text-white font-bold tracking-wide text-gray-900">Kontaktoplysninger</p>
      <div class="flex">
        <p class="mr-1 dark:text-white text-gray-800">Telefon:</p>
        <a href="tel:+4528898709" aria-label="Mit telefonnummer" title="Mit telefonnummer" class="transition-colors duration-300 text-green-400 hover:text-green-800">+45 28 89 87 09</a>
      </div>
      <div class="flex">
        <p class="mr-1 dark:text-white text-gray-800">Email:</p>
        <a href="mailto:scott@scottlind.dk" aria-label="Min email" title="Min email" class="transition-colors duration-300 text-green-400 hover:text-green-800">scott@scottlind.dk</a>
      </div>
    </div>
    <div>
      <span class="text-base dark:text-white font-bold tracking-wide text-gray-900">Socials</span>
      <div class="flex flex-end mt-1 space-x-3">
        <a href="https://www.linkedin.com/in/scottlind/" target="_blank" rel="noreferrer" class="text-gray-500 transition-colors duration-300 hover:text-blue-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" class="bi bi-linkedin" viewBox="0 0 16 16">
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
          </svg>
        </a>
        <a href="https://github.com/scottlinddk" target="_blank" rel="noreferrer" class="text-gray-500 transition-colors duration-300 hover:text-purple-600">
          <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" class="bi bi-github" viewBox="0 0 16 16">
            <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z"/>
          </svg>
        </a>
      </div>
    </div>
  </div>
  <div class="flex flex-col-reverse justify-between pt-5 pb-10 border-t lg:flex-row">
    <p class="text-sm dark:text-white text-gray-600">
      © Copyright 2021 Scott Lind
    </p>
    <p class="text-sm dark:text-white text-gray-600">
      Made with Vue & Tailwind
    </p>
  </div>
</footer>
</template>

<script>
import axios from 'axios';




export default {
  name: 'AppFooter',
  components: {

  },
  props: {
    wpObj: {
      type: Object,
      // required: true
    }
  },
  data() {
    return {
      menu: process.env.VUE_APP_MENU,
      footerObj: {},
      errors: [],
    };
  },
    async mounted () {
    try {
      const response = await axios.get(this.menu)
      this.footerObj = response.data.items
      console.log(typeof this.footerObj)

    } catch (e) {
      this.errors.push(e)
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
