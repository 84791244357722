<template>
<div>
  
</div>
</template>

<script>
// import axios from 'axios'

export default {
  name: 'LandingProjects',
  props: {
    wpObj: {
      type: Object,

    }
  },
  data() {
    return {
      projects: this.wpObj[0],
      errors: []
    }
  },
  //   async mounted () {
  //   try {
  //     const response = await axios.get(this.api)
  //     this.wpObj = response.data[0]
  //     console.log(this.wpObj)
  //     // console.log(this.$route.params.slug)
  //   } catch (e) {
  //     this.errors.push(e)
  //   }
  // }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>

</style>
