import { createRouter, createWebHistory } from 'vue-router'
import Hjem from '../views/Hjem.vue'

const routes = [
  {
    path: '/',
    name: 'Hjem',
    component: Hjem
  },
  {
    path: '/om-mig',
    name: 'Om mig',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/OmMig.vue')
  },
  {
    path: '/cases',
    name: 'Cases',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Cases.vue')
  },
  {
    path: '/kontakt',
    name: 'Kontakt',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Kontakt.vue')
  },
  {
    path: '/projekter/:slug',
    name: 'ProjektSide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/ProjektSide.vue')
  },
  {
    path: '/cv',
    name: 'CV',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/CV.vue')
  },
  { 
    path: '/:pathMatch(.*)*', 
    name: 'NotFound', 
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFound.vue')
  },
  // will match anything starting with `/user-` and put it under `$route.params.afterUser`
  { 
    path: '/projekter/:slug(.*)',
    name: 'NotFoundProject',
    component: () => import(/* webpackChunkName: "about" */ '../views/NotFoundProject.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
