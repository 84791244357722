<template>
  <AppHeader :wp-obj="wpObj"/>
  <router-view class="" v-slot="{ Component }">
    <transition name="route" mode="out-in">
      <component :is="Component">
      </component>
    </transition>
  </router-view>

  <div v-if="errors > 0" class="text-red">Noget gik galt da der skulle indlæses data 😔 Prøv at genindlæse siden.</div>

  <transition name="fade">
    <div id="pagetop" class="fixed right-5 bottom-5 cursor-pointer" v-show="scY > 300" @click="toTop">
      <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="none"
           stroke="#4a5568"
           stroke-width="1" stroke-linecap="square" stroke-linejoin="arcs">
        <path d="M18 15l-6-6-6 6"/>
      </svg>
    </div>
  </transition>
  <AppFooter :wp-obj="wpObj" />
</template>
<script>
import axios from 'axios';



// import AppVideo from './components/AppVideo.vue'
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
// import AppCases from './components/AppCases.vue'
// import AppPhoto from './components/AppPhoto.vue'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppFooter,
  },
  data() {
    return {
      wpObj: {},
      api: process.env.VUE_APP_API_HOME,
      errors: [],
      scTimer: 0,
      scY: 0,
    }
  },
  async mounted () {
    try {
      const response = await axios.get(this.api)
      this.wpObj.push(response.data[0])
      console.log(this.wpObj)
      // console.log(this.$route.params.slug)
    } catch (e) {
      this.errors.push(e)
    }
    window.addEventListener('scroll', this.handleScroll);
  },
  methods: {
      handleScroll: function () {
        if (this.scTimer) return;
        this.scTimer = setTimeout(() => {
          this.scY = window.scrollY;
          clearTimeout(this.scTimer);
          this.scTimer = 0;
        }, 50);
      },
      toTop: function () {
        window.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      },
    },
}
</script>

<style>
/* @import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@0;1&family=Montserrat:wght@100;300;400;600;700;800;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=DM+Serif+Display:ital@0;1&display=swap');

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Serif Display', serif;
}
p, span {
  font-family: 'DM Sans', Avenir, Helvetica, Arial, sans-serif;
}
/* route transition */ 
.route-enter-from {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 0.3s ease-out;
}
.route-leave-to {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-active {
  transition: all 0.3s ease-in;
}
/* router links*/
.router-link-active, .router-link-exact-active li a {
  color: #059669;
}
</style>
