<template>
<main>
  <section>
    <!-- <AppHeroComponent :wp-obj="wpObj"/> -->
    <Hero :wp-obj="wpObj"/>
    <LandingProjects :wp-obj="wpObj"/>
    <SliderComponent />

  </section>
</main>
</template>

<script>
import axios from 'axios';

// @ is an alias to /src
import Hero from '/src/components/Hero.vue'
// import AppHeroComponent from '/src/components/AppHeroComponent.vue'
import LandingProjects from '/src/components/LandingProjects.vue'
import SliderComponent from '/src/components/SliderComponent.vue'


export default {
  name: 'Hjem',

  components: {
    // AppHeroComponent,
    Hero,
    LandingProjects,
    SliderComponent,

  },  
  data() {
    return {
      wpObj: {},
      api: process.env.VUE_APP_API_HOME,
      errors: [],
    }
  },
  async mounted () {
    try {
      const response = await axios.get(this.api)
      this.wpObj = response.data[0]
      console.log(this.wpObj)
      // console.log(this.$route.params.slug)
    } catch (e) {
      this.errors.push(e)
    }
  }
}
</script>
<style>
</style>